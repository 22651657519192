import * as React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { css } from "@emotion/core";

const ConfirmedPage = () => {
  return (
    <Layout>
      <SEO title={"Email confirmed."} />
      <div
        className={"text-center w-90 max-w-screen"}
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      >
        <h1 className={"text-3xl md:text-4xl font-bold leading-tight"}>
          Your email is confirmed.
        </h1>
        <p>
          Thank you for confirming your email. Gift cards should go out within
          one week, so keep an eye on your email. Recipe and video coming soon.
        </p>
      </div>
    </Layout>
  );
};

export default ConfirmedPage;
