import React from "react";
import Nav from "./Nav";
// import SocialIcons from "./SocialIcons";

import CiscoPng from "../images/cisco-partner-logo.png";
import IqLogo from "../images/iq-logo-wide-white.png";
import DamChickenLogo from "../images/dam-chicken-logo.png";
import { css } from "@emotion/core";
import IqPlanesPng from "../images/iq-planes.png";

function Layout({ children }) {
  return (
    <div
      className="flex flex-col min-h-screen pt-16"
      css={css`
        background-image: url('${IqPlanesPng}');
        background-repeat: no-repeat;
        background-position: top right;
      `}
    >
      <Nav />
      <main className="relative mx-auto w-full flex-grow py-4 md:py-8 max-w-6xl">
        {children}
      </main>

      <footer className="bg-primary text-white">
        <div className="flex flex-wrap justify-center space-y-4 items-center py-8">
          <div className="flexw-full md:w-auto px-4 justify-center">
            <a href="https://www.wemaketechsimple.com">
              <img src={IqLogo} alt="Inter-Quest logo" />
            </a>
          </div>
          <div className="flex w-full md:w-auto px-4 justify-center">
            <img
              src={CiscoPng}
              alt="Cisco partner logo"
              className="h-24 w-auto"
            />
          </div>
          <div className="flex w-full md:w-auto px-4 justify-center">
            <a href="https://www.damchicken.com">
              <img
                src={DamChickenLogo}
                alt="Dam Chicken logo"
                className="h-24 w-auto"
              />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Layout;
